import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import tw, { styled } from "twin.macro"

import SEO from "../components/seo"
import SearchForm from "../components/search/index"

const Wrapper = styled.div`
	${tw`relative z-1 py-4 md:py-8 xl:py-16 bg-asphalt text-white`}
	.address {
		${tw`text-granny`}
	}

	.title {
		${tw`text-sunset`}
	}
`

const NotFoundPage = () => {
	const siteTitle = "Search Form Embed"

	const [state, setState] = React.useState({
		active: "address",
	})

	const SVGIcon = active => {
		setState(state => ({
			...state,
			active,
		}))
	}

	const {
		file: {
			childImageSharp: { fluid: ImageSrc },
		},
		wp: { frontPage: data },
	} = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "shutterstock_6421675751301.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 1000, maxHeight: 600) {
						...GatsbyImageSharpFluid_withWebp_tracedSVG
					}
				}
			}
			wp {
				frontPage {
					headings {
						search
					}
				}
			}
		}
	`)

	return (
		<Wrapper id="property-search">
			<SEO title={siteTitle} />

			<div className="container">
				<div tw="flex flex-wrap -mx-4">
					<div tw="px-4 w-full md:w-1/2">
						<h1 tw="relative mt-4 md:mt-8">
							{parse(data.headings.search)}

							<svg width="34" height="43" tw="absolute top-full left-0 mt-4" className={state.active}>
								<g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
									<g transform="translate(-160.000000, -415.000000)" fill="currentColor">
										<g transform="translate(160.029437, 415.029437)">
											<path
												d="M33.2340187,17.6776695 L28.2842712,22.627417 C27.893747,23.0179413 27.260582,23.0179413 26.8700577,22.627417 L17.6776695,13.4350288 C17.2871452,13.0445046 16.6539803,13.0445046 16.263456,13.4350288 L7.07106781,22.627417 C6.68054352,23.0179413 6.04737854,23.0179413 5.65685425,22.627417 L0.707106781,17.6776695 C0.316582489,17.2871452 0.316582489,16.6539803 0.707106781,16.263456 L16.263456,0.707106781 C16.6539803,0.316582489 17.2871452,0.316582489 17.6776695,0.707106781 L33.2340187,16.263456 C33.624543,16.6539803 33.624543,17.2871452 33.2340187,17.6776695 Z"
												transform="translate(16.970563, 11.667262) rotate(-180.000000) translate(-16.970563, -11.667262)"
											/>
											<path
												d="M33.2340187,37.6776695 L28.2842712,42.627417 C27.893747,43.0179413 27.260582,43.0179413 26.8700577,42.627417 L17.6776695,33.4350288 C17.2871452,33.0445046 16.6539803,33.0445046 16.263456,33.4350288 L7.07106781,42.627417 C6.68054352,43.0179413 6.04737854,43.0179413 5.65685425,42.627417 L0.707106781,37.6776695 C0.316582489,37.2871452 0.316582489,36.6539803 0.707106781,36.263456 L16.263456,20.7071068 C16.6539803,20.3165825 17.2871452,20.3165825 17.6776695,20.7071068 L33.2340187,36.263456 C33.624543,36.6539803 33.624543,37.2871452 33.2340187,37.6776695 Z"
												transform="translate(16.970563, 31.667262) rotate(-180.000000) translate(-16.970563, -31.667262)"
											/>
										</g>
									</g>
								</g>
							</svg>
						</h1>
					</div>

					<div tw="px-4 w-full md:w-1/2">
						<Image fluid={ImageSrc} alt="Check That Property" />
					</div>
				</div>

				<div tw="pt-px px-4 md:px-0">
					<SearchForm update={SVGIcon} embed="true" />
				</div>
			</div>
		</Wrapper>
	)
}

export default NotFoundPage
